import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import Section from '../../Section'

const BlockRecentlyTunedSection = styled(Section)`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: ${props => props.paddingTop}px;
	padding-bottom: ${props => props.paddingBottom}px;
`

const CarsContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: baseline;

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		flex-direction: row;
	}
`

const CarBox = styled.div`
	display: flex;
	/* width: 33.33333%; */
	padding: 1rem;
	flex-direction: column;

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		width: 50%;
	}

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		width: 33.33333%;
	}
`

const Car = styled.div`
	/* min-width: 60%; */
`

const Name = styled.h5`
	margin: 5px 5px;
	font-size: 1rem;
`

const Model = styled.p`
	margin: 0 5px;
	font-size: 1rem;
`

const CarData = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 1rem;
`

const CarSpecifications = styled.div`
	display: flex;
	flex-direction: column;
	margin: 5px;
	position: relative;
	right: 0;
`

const HP = styled.div``

const OriHP = styled.span`
	font-family: ${props => props.theme.fonts.big};
`

const AddedHP = styled.span`
	color: ${props => props.theme.colors.primary};
	font-family: ${props => props.theme.fonts.big};
	position: absolute;
	right: 0;
`

const NM = styled.div``

const OriNM = styled.span`
	font-family: ${props => props.theme.fonts.big};
`

const AddedNM = styled.span`
	color: ${props => props.theme.colors.primary};
	font-family: ${props => props.theme.fonts.big};
	margin-left: 10px;
`

const CarImage = styled(GatsbyImage)`
	height: 100%;

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		height: 250px;
	}
`

export {
	BlockRecentlyTunedSection,
	CarsContainer,
	CarBox,
	CarSpecifications,
	Car,
	CarData,
	Name,
	Model,
	HP,
	NM,
	OriHP,
	AddedHP,
	OriNM,
	AddedNM,
	CarImage
}
