import React from 'react'
import { useRecentlyTunedPosts } from '../../../hooks/useRecentlyTunedPosts'
import LinkOrButton from '../../LinkOrButton'
import { getImage } from 'gatsby-plugin-image'

import {
	BlockRecentlyTunedSection,
	CarsContainer,
	CarImage,
	CarBox,
	Car,
	CarData,
	CarSpecifications,
	Name,
	Model,
	HP,
	OriHP,
	AddedHP,
	OriNM,
	AddedNM,
	NM
} from './BlockRecentlyTuned.styled'

// WIP: finish styling
const BlockRecentlyTuned = acfData => {
	const recentlyTunedData = useRecentlyTunedPosts()

	return (
		<BlockRecentlyTunedSection
			paddingBottom={acfData.paddingBottom}
			paddingTop={acfData.paddingTop}
			position={acfData.imagePosition}
		>
			<CarsContainer>
				{recentlyTunedData &&
					recentlyTunedData.slice(0, acfData.maximumPostsCount).map((recentlyTunedPost, index) => (
						<CarBox key={index}>
							<CarImage
								image={getImage(recentlyTunedPost.postContent.image.localFile)}
								alt={recentlyTunedPost.postContent.image.altText}
							/>
							<CarData>
								<Car>
									<Name>{recentlyTunedPost.postContent.brand}</Name>
									<Model>{recentlyTunedPost.postContent.model}</Model>
								</Car>
								<CarSpecifications>
									<HP>
										<OriHP>{recentlyTunedPost.postContent.originalHp}PK</OriHP>
										<AddedHP>+{recentlyTunedPost.postContent.addedHp}PK</AddedHP>
									</HP>
									<NM>
										<OriNM>{recentlyTunedPost.postContent.originalNm}NM</OriNM>
										<AddedNM>+{recentlyTunedPost.postContent.addedNm}NM</AddedNM>
									</NM>
								</CarSpecifications>
							</CarData>
						</CarBox>
					))}
			</CarsContainer>

			{acfData.showReadmoreButton && acfData.link && (
				<LinkOrButton type={'button'} url={acfData.link.url} text={acfData.buttonText} />
			)}
		</BlockRecentlyTunedSection>
	)
}

export default BlockRecentlyTuned
