import { useStaticQuery, graphql } from 'gatsby'

export const useRecentlyTunedPosts = () => {
	const data = useStaticQuery(
		graphql`
			query {
				allWpRecentlyTunedPost(sort: { fields: [date], order: DESC }, limit: 18) {
					edges {
						node {
							title
							databaseId
							slug
							id
							uri
							postContent {
								image {
									altText
									sourceUrl
									localFile {
										childImageSharp {
											gatsbyImageData(width: 700, height: 450)
										}
									}
								}
								addedHp
								addedNm
								brand
								model
								originalHp
								originalNm
							}
						}
					}
				}
			}
		`
	)
	return data.allWpRecentlyTunedPost.edges.map(edge => edge.node)
}
